import { withDependencies, multi, ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import {
	LifeCycle,
	Props,
	CompEventsRegistrarSym,
	ICompEventsRegistrar,
	Context,
	IContext,
	IPropsStore,
	IAppWillMountHandler,
	IRenderer,
	RendererSymbol,
	DomReadySymbol,
	StructureAPI,
	IStructureAPI,
	ILogger,
	LoggerSymbol,
} from '@wix/thunderbolt-symbols'
import { AppProps } from 'feature-react-renderer'
import { NavigationInfoSym, Thunderbolt } from './types'
import { IThunderbolt } from './IThunderbolt'
import { createDomReadyPromise, WaitForDomReady } from './DomReady'
import { NavigationInfo } from './NavigationInfo'
import { taskify } from '@wix/thunderbolt-commons'

type ThunderboltImplFactory = (
	structureAPI: IStructureAPI,
	propsStore: IPropsStore,
	compEventsRegistrar: ICompEventsRegistrar,
	context: IContext,
	initializables: Array<IAppWillMountHandler>,
	renderer: IRenderer<AppProps, any>,
	logger: ILogger
) => IThunderbolt

const thunderboltImpl: ThunderboltImplFactory = (
	structureAPI,
	propsStore,
	compEventsRegistrar,
	context,
	initializables,
	renderer,
	logger
) => {
	context.extend({
		structure: structureAPI,
		props: propsStore,
		compEventsRegistrar,
		logger, // TODO should come from non-store
	})

	const getAppProps = () => context.get() as AppProps
	return {
		ready: async () => {
			await structureAPI.addShellStructure()
			const initThings = initializables.map((x) => taskify(() => x.appWillMount()))
			await Promise.all(initThings)
		},
		render: () => {
			return renderer.render(getAppProps())
		},
		getAppProps,
	}
}

const ThunderboltImpl = withDependencies(
	[
		StructureAPI,
		Props,
		CompEventsRegistrarSym,
		Context,
		multi(LifeCycle.AppWillMountHandler),
		RendererSymbol,
		LoggerSymbol,
	],
	thunderboltImpl
)

export const site: ContainerModuleLoader = (bind) => {
	bind(Thunderbolt).to(ThunderboltImpl)
	if (process.env.browser) {
		bind(DomReadySymbol).toConstantValue(createDomReadyPromise())
		bind(LifeCycle.AppWillMountHandler).to(WaitForDomReady)
	}
	const navigationInfo = new NavigationInfo()
	bind(LifeCycle.PageWillMountHandler).toConstantValue(navigationInfo)
	bind(NavigationInfoSym).toConstantValue(navigationInfo)
}
