import { withDependencies } from '@wix/thunderbolt-ioc'
import { WarmupDataMap } from '@wix/thunderbolt-symbols'
import { IWarmupDataProvider } from './types'
import { WarmupDataPromiseSymbol } from './symbols'

const warmupDataProvider = (warmupDataPromise: Promise<WarmupDataMap>): IWarmupDataProvider => ({
	async getWarmupData<
		WarmupDataType extends WarmupDataMap = never,
		K extends keyof WarmupDataType = keyof WarmupDataType
	>(key: K) {
		const warmupData = (await warmupDataPromise) as Partial<WarmupDataType>
		return warmupData[key] ?? null
	},
})

export const WarmupDataProvider = withDependencies([WarmupDataPromiseSymbol], warmupDataProvider)
