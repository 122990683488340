type Callback = () => any
type BatchingFunction = (callback: Callback) => void
export interface BatchingStrategy {
	batch: (callback: Callback) => void
}

export const createBatchingStrategy = (batchingFunction: BatchingFunction = (fn) => fn()): BatchingStrategy => {
	return {
		batch: (fn) => batchingFunction(fn),
	}
}
