import { ContainerModuleLoader, withDependencies } from '@wix/thunderbolt-ioc'
import { PageResourceFetcherSymbol, PageStyleLoaderSymbol } from './symbols'
import { IPageResourceFetcher } from './IPageResourceFetcher'
import { ClientPageStyleLoader, ILoadPageStyle, ServerPageStyleLoader } from './PageStyleLoader'
import { IPageAssetsLoader, PageAssets, PageAssetsLoaderSymbol, SiteAssetsResources } from '@wix/thunderbolt-symbols'
import { PageResourceFetcher } from './PageResourceFetcher'

type PageAssetsLoaderFactory = (
	pageResourceFetcher: IPageResourceFetcher,
	pageStyleLoader: ILoadPageStyle
) => IPageAssetsLoader

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T

const createPageAssetsExtractor = (pageFeatures: Promise<SiteAssetsResources['features']>) => <
	K extends keyof PageAssets
>(
	extractor: (result: ThenArg<SiteAssetsResources['features']>) => ThenArg<PageAssets[K]>,
	fallabckValue: ThenArg<PageAssets[K]>
) => pageFeatures.catch(() => null).then((result) => (result === null ? fallabckValue : extractor(result)))

const pageAssetsLoaderImplFactory: PageAssetsLoaderFactory = (pageResourceFetcher, pageStyleLoader) => {
	const assetsCache: Record<string, PageAssets> = {}

	const createPageAssets = (pageCompId: string): PageAssets => {
		const addCssPromise = pageStyleLoader.load(pageCompId)
		const pageFeatures = pageResourceFetcher.fetchResource(pageCompId, 'features', 'enable')

		const extractByPageAssetType = createPageAssetsExtractor(pageFeatures)

		return {
			components: extractByPageAssetType<'components'>(({ structure: { components } }) => components, {}),
			features: extractByPageAssetType<'features'>(({ structure: { features } }) => features, []),
			siteFeaturesConfigs: extractByPageAssetType<'siteFeaturesConfigs'>(
				({ structure: { siteFeaturesConfigs } }) => siteFeaturesConfigs,
				{}
			),
			props: extractByPageAssetType<'props'>(({ props }) => props, { render: { compProps: {} } }),
			css: addCssPromise,
		}
	}

	return {
		load: (pageCompId: string) => {
			assetsCache[pageCompId] = assetsCache[pageCompId] || createPageAssets(pageCompId)
			return assetsCache[pageCompId]
		},
	}
}

const PageAssetsLoaderImpl = withDependencies(
	[PageResourceFetcherSymbol, PageStyleLoaderSymbol],
	pageAssetsLoaderImplFactory
)

export const site: ContainerModuleLoader = (bind) => {
	bind<IPageAssetsLoader>(PageAssetsLoaderSymbol).to(PageAssetsLoaderImpl)
	bind<IPageResourceFetcher>(PageResourceFetcherSymbol).to(PageResourceFetcher)
	if (process.env.browser) {
		bind<ILoadPageStyle>(PageStyleLoaderSymbol).to(ClientPageStyleLoader)
	} else {
		bind<ILoadPageStyle>(PageStyleLoaderSymbol).to(ServerPageStyleLoader)
	}
}
