import { named, withDependencies } from '@wix/thunderbolt-ioc'
import { BIReporter, BISymbol, CommonConfig, SiteFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import { ICommonConfig, ICommonConfigState } from './types'
import { name } from './symbols'

const commonConfigFactory = (initialCommonConfig: CommonConfig, biReporter: BIReporter): ICommonConfig => {
	const state: ICommonConfigState = {
		commonConfig: initialCommonConfig,
		subscribers: [],
	}

	return {
		getCommonConfig: () => state.commonConfig,
		updateCommonConfig: (config: Partial<CommonConfig>) => {
			if (config.hasOwnProperty('bsi')) {
				biReporter.setDynamicSessionData({ bsi: config.bsi })
			}
			state.commonConfig = { ...state.commonConfig, ...config }
			state.subscribers.forEach((subscriber) => subscriber(state.commonConfig))
		},
		registerToCommonConfigChange: (subscriber) => state.subscribers.push(subscriber),
	}
}

export const CommonConfigImpl = withDependencies([named(SiteFeatureConfigSymbol, name), BISymbol], commonConfigFactory)
