import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { getStore } from './stores'
import { StructureAPI } from './structureApi'
import {
	AppStructure,
	PropsMap,
	CompActions,
	IStructureStore,
	IPropsStore,
	Props,
	ICompActionsStore,
	CompActionsSym,
	IStructureAPI,
	StructureAPI as StructureAPISym,
} from '@wix/thunderbolt-symbols'
import { Structure } from './symbols'

export { Structure }

export const site: ContainerModuleLoader = (bind) => {
	const structure = getStore<AppStructure>()
	const props = getStore<PropsMap>()
	const compActions = getStore<CompActions>()

	// Serializable
	bind<IStructureStore>(Structure).toConstantValue(structure)
	bind<IPropsStore>(Props).toConstantValue(props)
	// Not Serializable
	bind<ICompActionsStore>(CompActionsSym).toConstantValue(compActions)

	bind<IStructureAPI>(StructureAPISym).to(StructureAPI)
}
