import {
	IAppWillLoadPageHandler,
	ILogger,
	IPageWillMountHandler,
	LifeCycle,
	LoggerSymbol,
} from '@wix/thunderbolt-symbols'
import { IPageProvider, PageProviderSymbol } from 'feature-pages'
import { ContainerModuleLoader, withDependencies } from '@wix/thunderbolt-ioc'

const runPageWillMountHandlers = async (pageReflectorProvider: IPageProvider, pageId: string, contextId: string) => {
	const pageReflector = await pageReflectorProvider(contextId)
	const handlers = pageReflector.getAllImplementersOf<IPageWillMountHandler>(LifeCycle.PageWillMountHandler)
	return Promise.all(handlers.map((handler) => handler.pageWillMount(pageId)))
}

const pageInitializer = (pageProvider: IPageProvider, logger: ILogger): IAppWillLoadPageHandler => ({
	appWillLoadPage: async ({ pageId, contextId }) => {
		logger.phaseMark('init_page')
		await runPageWillMountHandlers(pageProvider, pageId, contextId)
	},
})

export const site: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.AppWillLoadPageHandler).to(withDependencies([PageProviderSymbol, LoggerSymbol], pageInitializer))
}
