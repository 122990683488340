import {
	IAppWillLoadPageHandler,
	IPageDidMountHandler,
	IPageDidUnmountHandler,
	IPropsStore,
	LifeCycle,
	Props,
} from '@wix/thunderbolt-symbols'
import { withDependencies } from '@wix/thunderbolt-ioc'
import { PageProviderSymbol, IPageProvider } from 'feature-pages'

const addPageMountAndUnmountHandlers = async (
	pageId: string,
	contextId: string,
	props: IPropsStore,
	pageReflectorProvider: IPageProvider
) => {
	const pageReflector = await pageReflectorProvider(contextId)
	const pageDidMountHandlers = pageReflector.getAllImplementersOf<IPageDidMountHandler>(LifeCycle.PageDidMountHandler)
	const pageDidUnmountHandlers = pageReflector
		.getAllImplementersOf<IPageDidUnmountHandler>(LifeCycle.PageDidUnmountHandler)
		.map((m) => m.pageDidUnmount)

	props.update({
		[pageId]: {
			key: contextId,
			pageDidMount: async (page: HTMLElement) => {
				if (page) {
					const funcs = await Promise.all(
						pageDidMountHandlers.map((pageDidMountHandler) =>
							pageDidMountHandler.pageDidMount(pageId, page)
						)
					)

					const unsubscribeFuncs = funcs.filter((x) => x) as Array<Exclude<typeof funcs[number], void>>
					pageDidUnmountHandlers.push(...unsubscribeFuncs)
				} else {
					pageDidUnmountHandlers.forEach((pageDidUnmount) => pageDidUnmount())
				}
			},
		},
	})
}

export const PageMountUnmountSubscriber = withDependencies(
	[Props, PageProviderSymbol],
	(props: IPropsStore, pageReflectorProvider: IPageProvider): IAppWillLoadPageHandler => ({
		appWillLoadPage: ({ pageId, contextId }) =>
			addPageMountAndUnmountHandlers(pageId, contextId, props, pageReflectorProvider),
	})
)
