import { withDependencies } from '@wix/thunderbolt-ioc'
import { WixBiSessionSymbol, WixBiSession } from '@wix/thunderbolt-symbols'
import { SessionManagerSymbol, ISessionManager } from 'feature-session-manager'
import { createBusinessLoggerFactory } from './businessLoggerCommon'
import * as Types from './types'
import { BsiManagerSymbol } from './symbols'
import { IBsiManager } from './types'

/**
 * BI logger for business events
 *
 * - Initialized with base defaults + bsi (which are supported globally in the BI schema).
 * - Any additional defaults should be added only after making sure the BI schema supports them.
 *
 * Please use #bi-logger-support for any questions
 */
const businessLoggerFactory = (
	wixBiSession: WixBiSession,
	sessionManager: ISessionManager,
	bsiManager: IBsiManager
): BusinessLogger => {
	const biLoggerFactory = createBusinessLoggerFactory(wixBiSession, sessionManager).withNonEssentialContext({
		bsi: () => bsiManager.getBsi(),
	})

	return {
		logger: biLoggerFactory.logger(),
	}
}

export type BusinessLogger = Types.BusinessLogger
export const BusinessLogger = withDependencies(
	[WixBiSessionSymbol, SessionManagerSymbol, BsiManagerSymbol],
	businessLoggerFactory
)
