import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { SessionManager } from './sessionManager'
import { SessionManagerSymbol, DynamicSessionModelSymbol, name } from './symbols'
import { WixCodeSdkHandlersProviderSym } from '@wix/thunderbolt-symbols'

export const site: ContainerModuleLoader = (bind, bindAll) => {
	bindAll([SessionManagerSymbol, WixCodeSdkHandlersProviderSym], SessionManager)
}

export { ISessionManager, DynamicSessionModel, SessionHandlers, Instance } from './types'

export { SessionManagerSymbol, DynamicSessionModelSymbol, name }
