import { withDependencies, ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { Context, IContext } from '@wix/thunderbolt-symbols'

const contextImpl = (): IContext => {
	const context = {}
	return {
		extend: (partialContext: object) => {
			Object.assign(context, partialContext)
		},

		get: () => {
			return context
		},
	}
}

export const ContextImpl = withDependencies([], contextImpl)

export const site: ContainerModuleLoader = (bind) => {
	bind(Context).to(ContextImpl)
}
