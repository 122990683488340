import { IRoutingConfig, Route, RouteInfo } from './types'
import { queryParamsWhitelist } from './queryParamsWhitelist'
import { resolveQueryParams } from './urlUtils'

type RouteData = {
	relativeUrl: string
	route: Route
}

const getRelativePathname = (url: string, baseUrl: string): string => {
	const parsedUrl = new URL(url, `${baseUrl}/`)
	const parsedBaseUrl = new URL(baseUrl)

	return parsedUrl.pathname.replace(parsedBaseUrl.pathname, '')
}

const removeLeadingAndTrailingSlash = (str: string): string => /^\/?(.*?)\/?$/.exec(str)![1]

const getRelativePathnameParts = (relativePathname: string) => {
	const cleanPath = removeLeadingAndTrailingSlash(relativePathname)

	try {
		return decodeURIComponent(cleanPath).split('/')
	} catch (e) {
		return cleanPath.split('/')
	}
}

const getRelativePathPartsForRelativeUrl = (url: string, baseUrl: string): Array<string> => {
	const relativePathname = getRelativePathname(url, baseUrl)

	return getRelativePathnameParts(relativePathname)
}

const pathnamePartsToRelativeUrl = (pathnameParts: Array<string>): string => `./${pathnameParts.join('/')}`

const isInternalUrl = (url: string, baseUrl: string): boolean => {
	const parsedUrl = new URL(url, `${baseUrl}/`)
	const parsedBaseUrl = new URL(baseUrl)
	return parsedUrl.host === parsedBaseUrl.host && parsedUrl.pathname.startsWith(parsedBaseUrl.pathname)
}

export const getRelativeUrl = (url: string, baseUrl: string) => {
	const relativePathname = getRelativePathname(url, baseUrl)

	return pathnamePartsToRelativeUrl(getRelativePathnameParts(relativePathname))
}

const getRouteData = (url: string, baseUrl: string, routes: IRoutingConfig['routes']): RouteData | undefined => {
	if (!isInternalUrl(url, baseUrl)) {
		return undefined
	}

	const pathnameParts = getRelativePathPartsForRelativeUrl(url, baseUrl)
	const routeKey = `./${pathnameParts[0]}`
	const route = routes[routeKey]

	return route ? { relativeUrl: pathnamePartsToRelativeUrl(pathnameParts), route } : undefined
}

const keepInternalQueryParams = (currentQueryParams: string) => {
	const currentRouteInfoQueryParams = new URLSearchParams(currentQueryParams)
	const { names, matchers } = queryParamsWhitelist
	currentRouteInfoQueryParams.forEach((value: any, key: any) => {
		if (!(names.has(key) || matchers.some((matcher) => key.match(matcher)))) {
			currentRouteInfoQueryParams.delete(key)
		}
	})

	return currentRouteInfoQueryParams.toString()
}

export const resolveUrl = (
	url: string,
	routingConfig: IRoutingConfig,
	currentRouteInfo?: RouteInfo | null
): Partial<RouteInfo> => {
	const isHomePageUrl = url === './'
	const queryParams = currentRouteInfo ? keepInternalQueryParams(currentRouteInfo.parsedUrl.search) : ''

	// should resolve to base url on home page url, otherwise we get extra slash on navigation
	const urlToParse = isHomePageUrl ? routingConfig.baseUrl : url

	const parsedUrl = new URL(urlToParse, `${routingConfig.baseUrl}/`)
	const routeData = getRouteData(urlToParse, routingConfig.baseUrl, routingConfig.routes)

	parsedUrl.search = resolveQueryParams(parsedUrl.search, queryParams)

	if (routeData) {
		const { relativeUrl, route } = routeData

		return {
			...route,
			relativeUrl,
			parsedUrl,
		}
	}

	return {
		parsedUrl,
	}
}
