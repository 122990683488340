import { multi, withDependencies } from '@wix/thunderbolt-ioc'
import { CurrentRouteInfoSymbol, IAppDidMountHandler, IRenderer, LifeCycle } from '@wix/thunderbolt-symbols'
import { AppProps, ClientRenderResponse } from '../types'
import App from '../components/App'
import React from 'react'
import ReactDOM from 'react-dom'
import { createBatchingStrategy } from '../components/batchingStrategy'
import { ICurrentRouteInfo } from 'feature-router'

type ReactClientRendererFactory = (
	appDidMountHandlers: Array<IAppDidMountHandler>,
	currentRouteInfo: ICurrentRouteInfo
) => IRenderer<AppProps, Promise<ClientRenderResponse>>

const reactClientRenderer: ReactClientRendererFactory = (appDidMountHandlers, currentRouteInfo) => ({
	render: async (props: AppProps) => {
		const target = document.getElementById('site-container') as HTMLElement
		const batchingStrategy = createBatchingStrategy((fn) => ReactDOM.unstable_batchedUpdates(fn))
		await new Promise((resolve) =>
			ReactDOM.hydrate(
				<React.StrictMode>
					<App {...props} batchingStrategy={batchingStrategy} />
				</React.StrictMode>,
				target,
				resolve
			)
		)

		appDidMountHandlers.map((appDidMountHandler) => appDidMountHandler.appDidMount(target))
		const route = currentRouteInfo.getCurrentRouteInfo()
		return {
			firstPageId: (route && route.pageId) || 'PROTECTED',
		}
	},
})

export const ReactClientRenderer = withDependencies(
	[multi(LifeCycle.AppDidMountHandler), CurrentRouteInfoSymbol],
	reactClientRenderer
)
