import { ContainerModuleLoader, withDependencies } from '@wix/thunderbolt-ioc'
import { IStructureStore, ClientApi, IClientApi } from '@wix/thunderbolt-symbols'
import { ComponentsLoaderSymbol, IComponentsLoader } from '@wix/thunderbolt-components-loader'
import { Structure } from '../stores'

type ClientApiImplFactory = (structure: IStructureStore, componentsLoader: IComponentsLoader) => IClientApi

const clientApiImpl: ClientApiImplFactory = (structure, componentsLoader) => ({
	async loadAndRegisterComponentsFromStructure() {
		await componentsLoader.loadComponents(structure.getEntireStore())
	},
})

export const ClientApiImpl = withDependencies([Structure, ComponentsLoaderSymbol], clientApiImpl)

export const site: ContainerModuleLoader = (bind) => {
	bind(ClientApi).to(ClientApiImpl)
}
