import { WixBiSession } from '@wix/thunderbolt-symbols'
import { commonBiLoggerFactory } from '@wix/thunderbolt-commons'
import { ISessionManager } from 'feature-session-manager'

export const createBusinessLoggerFactory = (wixBiSession: WixBiSession, sessionManager: ISessionManager) => {
	const {
		initialTimestamp,
		dc,
		visitorId,
		siteMemberId,
		viewerSessionId,
		requestId,
		is_rollout,
		isCached,
		msId,
	} = wixBiSession

	const biStore = {
		msid: msId,
		visitorId,
		siteMemberId,
		viewerSessionId,
		requestId,
		initialTimestamp,
		dc,
		is_rollout,
		isCached,
		is_headless: false,
		rolloutData: {
			// TODO @shahaf
			siteAssetsVersionsRollout: false,
			isDACRollout: false,
			isTBRollout: false,
		},
		pageData: {
			// TODO @shahaf
			pageNumber: 0,
			pageId: 'TODO',
			pageUrl: 'TODO',
			isLightbox: false,
		},
	}

	return commonBiLoggerFactory.createBaseBiLoggerFactory({
		biStore,
		sessionManager,
	})
}
