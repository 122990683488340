import { IReporterApi } from '..'
import { ICurrentRouteInfo } from 'feature-router'
import { IPageNumber } from 'feature-business-logger'

export async function reportPageView(
	reporterApi: IReporterApi,
	currentRouteInfo: ICurrentRouteInfo,
	pageNumberHandler: IPageNumber
) {
	const routeInfo = currentRouteInfo?.getCurrentRouteInfo()
	const pageNumber = pageNumberHandler.getPageNumber()
	const pageData = {
		pagePath: routeInfo?.parsedUrl?.pathname.concat(routeInfo?.parsedUrl?.search),
		pageTitle: window.document.title,
		pageId: routeInfo?.pageId,
		pageNumber,
	}

	reporterApi.trackEvent({
		eventName: 'PageView',
		params: pageData,
		options: { reportToManagedChannels: true, context: { isFirstVisit: pageNumber === 1 } },
	})
}
